import React from "react"
import { graphql } from "gatsby"
import Title from "../../components/title"
import Layout from "../../components/layout"
import StyledHero from "../../components/styledHero"
import styles from "../../css/distressed.module.css"
import partnerStyles from "../../css/partners.module.css"
import SEO from "../../components/seo"
import distressedPartners from "../../constants/distressedPartners"

const distressedLadies = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Leistungsangebot Distressed Ladies" />
      <StyledHero img={data.distressedBcg.childImageSharp.fluid} />
      <section className={styles.distressed}>
        <Title title="Leistungsangebot" subtitle="Distressed Ladies" />
        <div className={styles.distressedCenter}>
          <header>
            <a
              href="http://distressed-ladies.com/de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.partnerLogo}
                src="/distressedLadies.png"
                alt="Distressed Ladies Logo"
              />
            </a>
            <h4>
              Pro Bono Leistungsangebot Distressed Ladies Women in Restructuring
              e.V.
            </h4>
          </header>
          <article>
            <p>
              Die Distressed Ladies sind Expertinnen aus den Bereichen Rechts- und
              Wirtschaftsberatung, Insolvenzverwaltung, Bankwesen, Interim Management,
              Corporate Finance Beratung sowie Vertreterinnen von Finanzierern und
              sonstigen insolvenz- und restrukturierungsnahen Dienstleistern.
            </p>
            <p>
              Die Vereinsmitglieder bieten im Rahmen dieser Initiative Erstberatungsgespräche
              zu den wichtigsten rechtlichen, finanzwirtschaftlichen und betriebswirtschaftlichen
              Themen rund um das Thema Auswirkungen von Corona auf Unternehmen und Unternehmer.
              Sie können sich insbesondere zu folgenden Themen für ein kostenloses Erstberatungsgespräch anmelden: 
            </p>
          </article>


          <article className={styles.offerText}>
            <p>
            Liquiditätsstärkende Maßnahmen: Grundprinzipien zur Liquiditätssicherung und Übersicht zu den Hilfen
              und Unterstützungsprogrammen von Bund und Ländern. 
            </p>
            <p>
              Haftungsrisiken für das Management in der Krise und Insolvenzantragstellung: 
              Aussetzung der Insolvenzantragspflicht und Haftungen nach dem COVInsAG, Weiterhin bestehende Haftungsrisiken für leitende Organe. 
            </p>
            <p>
              Allgemeiner Ablauf eines Insolvenzverfahrens, Darstellung der verschiedenen Verfahrensarten.
            </p>
            <p>
              Commercial Themen, wie allg. Leistungsverweigerungsrecht, Höhere Gewalt, Steuerrecht Kurzarbeitergeld;Steuererleichterungen (Stundung, Aussetzung Vorauszahlungen u.ä.) sowie arbeitsrechtliche Fragestellungen in der COVID-19-Phase.
            </p>
            <p>
              Immobilien-Management in der Krise (1)Immobilien: 
              Was tun, wenn Mietausfälle und Leerstand drohen? 
              Betriebswirtschaftliche Maßnahmen, bevor es zur Darlehenskündigung oder Notverkauf kommt. 
            </p>
            <p>
            Immobilien-Management in der Krise (2)Immobilien: 
            Sicherung der Liquidität bei Immobilieninvestments,Optimierung von Mieteinnahmen und Kosten sowie Maßnahmen zur Stabilisierung der wirtschaftlichen Situation von Immobilien. 
              </p>
          </article>

            <article className={styles.offerText}>
            <p>
            </p>
            <p>
              Bitte melden Sie sich über den folgenden Link unter Nennung Ihrer Kontaktdaten und Ihres konkreten Anliegens an.
              Wir stellen dann den Kontakt zu der richtigen Expertin für Sie her. 
            </p>
            <p>
              Dieses Angebot gilt zunächst befristet bis zum 30. Juni 2020.
            </p>
            </article>

          <footer>
            <a
              href="mailto:webkonferenz@vscovidunited.de"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-primary"
            >
              Hier anmelden
            </a>
          </footer>
        </div>
      </section>






      <section className={`${partnerStyles.partners} ${partnerStyles.grey}`}>
        <Title title="Distressed" subtitle="Ladies" />
        <div className={partnerStyles.center}>
          {distressedPartners.map((item) => {
            return item.people.map((subitem, index) => {
              return (
                <article key={index} className={partnerStyles.partner}>
                  <a
                    href={subitem.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className={partnerStyles.partnerPhoto}
                      src={subitem.src}
                      alt={subitem.name}
                    />
                    <h3>{subitem.name}</h3>
                    <h4>{subitem.role}</h4>
                  </a>
                </article>
              )
            })
          })}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    distressedBcg: file(relativePath: { eq: "showroomBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default distressedLadies
