export default [
  {
    src: "/distressedLadies.png",
    alt: "distressed ladies",
    url: "http://distressed-ladies.com/de/",
    people: [
      {
        src: "/katharina_gerdes.jpg",
        name: "Katharina Gerdes",
        url: "http://www.brl.de/",
        role:
          "Rechtsanwältin, Partnerin // BRL BOEGE ROHDE LUEBBEHUESEN Partnerschaft von Rechtsanwälten, Wirtschaftsprüfern, Steuerberatern mbB",
      },
      {
        src: "/sylwia_bea.jpg",
        name: "Dr. Sylwia Maria Bea",
        url: "http://distressed-ladies.com/de/",
        role:
          "Rechtsanwältin, Fachanwältin für Handels- und Gesellschaftsrecht, Local Partner // White & Case LLP",
      },
      {
        src: "/jasmin_urlaub.jpg",
        name: "Dr. Jasmin Urlaub",
        url: "http://www.menoldbezler.de/",
        role:
          "Fachanwältin für Insolvenzrecht, Partnerin // MENOLD BEZLER Rechtsanwälte Steuerberater Wirtschaftsprüfer Partnerschaft mbB",
      },
      {
        src: "/gesa_pantaleon.jpg",
        name: "Dr. Gesa Pantaleon gen. Stemberg",
        url: "http://www.verwalter.de/",
        role:
          "Rechtsanwältin, Fachanwältin für Insolvenzrecht, Partnerin // Hoefer I Schmidt-Thiem Rechtsanwälte und Insolvenzverwalter",
      },
      {
        src: "/petra_heidenfelder.jpg",
        name: "Petra Heidenfelder",
        url: "http://www.schneidergeiwitz.de/",
        role:
          "Rechtsanwältin, Fachanwältin für Insolvenz- und Arbeitsrecht, Mediatorin, Partnerin // SGP Schneider Geiwitz & Partner Wirtschaftsprüfer Steuerberater Rechtsanwälte PartGmbB",
      },
      {
        src: "/romy_metzger.jpg",
        name: "Dr. Romy Metzger",
        url: "https://www.schneidergeiwitz.de/",
        role:
          "Rechtsanwältin, Fachanwältin für Insolvenzrecht und Partnerin // SGP Schneider Geiwitz & Partner Wirtschaftsprüfer Steuerberater Rechtsanwälte PartGmbB",
      },
      {
        src: "/antje_mertig.jpg",
        name: "Antje Mertig",
        url: "https://steinberg.management/",
        role: "Geschäftsführerin // Steinberg Real Estate Management GmbH",
      },
      {
        src: "/andrea_metz.jpg",
        name: "Andrea Metz",
        url: "http://www.luther-lawfirm.com/",
        role:
          "Rechtsanwältin und Partnerin // Luther Rechtsanwaltsgesellschaft mbH",
      },
      {
        src: "/jessica_hirsch.jpg",
        name: "Jessica Hirsch",
        url: "http://www.struktur-management-partner.com/",
        role: "Partnerin // Struktur Management Partner GmbH",
      },
      {
        src: "/simone_kaldenbach.jpg",
        name: "Simone Kaldenbach",
        url: "http://www.schultze-braun.de/",
        role:
          "Rechtsanwältin, Fachanwältin für Insolvenzrecht // Schultze & Braun",
      },
    ],
  },
]
